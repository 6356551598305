<template>
<div>
    <div class="bg-bdy">
        <section class="bg-white">
            <div class="container bg-main pt-1 pb-1">
                <div class="h-100">
                    <swiper :keyboard="{
      enabled: true,
    }" :modules="[Autoplay,Navigation,Pagination,Keyboard]" :slides-per-view="1" :navigation="false" :autoplay="{
          delay: 2500,
            disableOnInteraction: false,
        }" class="template-swipper"><swiper-slide v-for="(carouselBanners,index) in (webMedias.carouselBanners)" v-bind:key="index">
                            <div class="img-banner-mo pointer"><nuxt-link-locale class="a-decoration-none" :to="carouselBanners.itemLink"><img class="br-5 img-fluid w-100 mobile-index-banner" :src="carouselBanners?.itemImage" :alt="websiteConfig.tenThuongHieuSeo"></nuxt-link-locale></div>
                        </swiper-slide></swiper>
                </div>
            </div><!-- Start: danh muc -->
            <div><!-- Start: cha -->
                <div class="d-flex flex-wrap py-3 mb-3">
                    <div class="col-4"><!-- Start: danhmuc1 -->
                        <div class="pointer mb-2" @click="userGlobalState.showmainmenugroupproduct=true"><!-- Start: img -->
                            <div class="d-flex justify-content-center mb-1"><!-- Start: img -->
                                <div class="d-flex justify-content-center item-category-img-top"><img class="img-fluid" src="/static/cosmeticscz/hasaki-danh-muc.png" alt="danh mục sản phẩm"></div><!-- End: img -->
                            </div><!-- End: img --><!-- Start: text -->
                            <div class="d-flex flex-column align-items-center"><span class="fs-12">Danh mục</span></div><!-- End: text -->
                        </div><!-- End: danhmuc1 -->
                    </div>
                    <div class="col-4" v-for="(listHomeMenu,index) in listHomeMenus" v-bind:key="listHomeMenu._id"><!-- Start: danhmuc1 -->
                        <div class="pointer mb-2"><nuxt-link-locale class="text-decoration-none text-dark" :to="listHomeMenu.link">
                                <div class="d-flex justify-content-center mb-1"><!-- Start: img -->
                                    <div class="d-flex justify-content-center item-category-img-top"><img class="img-fluid" alt="Deal hot" :src="listHomeMenu.icon"></div><!-- End: img -->
                                </div>
                                <div class="d-flex flex-column align-items-center"><span class="d-inline-flex fs-12">{{listHomeMenu.label}}</span></div>
                            </nuxt-link-locale></div><!-- End: danhmuc1 -->
                    </div>
                </div><!-- End: cha -->
            </div><!-- End: danh muc -->
        </section><!-- Start: list HOME SECTION -->
        <div><!-- Start: deals nổi bật -->
            <section class="mb-0">
                <div class="container">
                    <div><!-- Start: cha -->
                        <div class="pb-2" v-for="(homeSections,index) in homeSectionss" v-bind:key="homeSections._id"><!-- Start: title-deals -->
                            <div class="d-flex justify-content-between align-items-center py-1">
                                <h4 class="title-deal mb-0">{{homeSections.sectionName}}</h4><nuxt-link-locale class="text-decoration-none text-dark" aria-label="xem các sản phẩm hot deals" :to="'/bo-suu-tap/'+homeSections.slug"><i class="icon ion-chevron-right ps-3 py-1"></i></nuxt-link-locale>
                            </div><!-- End: title-deals --><!-- Start: list -->
                            <div class="list-item-cat-mo h-100">
                                <div class="col-5 me-1" v-for="(listProducts,index) in homeSections?.listProducts||[]" v-bind:key="listProducts"><!-- Start: product-card -->
                                    <div class="product-card pointer item-transform-img h-100 p-1 p-lg-2 main-box-shadown"><nuxt-link-locale class="text-decoration-none text-dark" :to="'/san-pham/'+listProducts.slug">
                                            <div class="item-card-img mb-1"><img class="img-fluid w-100" alt="hình ảnh của sản phẩm" :src="listProducts.image"></div>
                                            <div class="item-info-card pb-1"><!-- Start: Basic Price -->
                                                <div class="d-flex justify-content-between item-card-price" v-if="listProducts.marketPrice===listProducts.price || listProducts.marketPrice===null || listProducts.marketPrice===0">
                                                    <div><span>{{formatNumberMoney(listProducts?.price)}}</span></div>
                                                </div><!-- End: Basic Price --><!-- Start: discount price -->
                                                <div class="d-flex flex-column justify-content-between flex-md-row item-card-price h-100" v-if="(listProducts.marketPrice!==listProducts.price)&&(listProducts.marketPrice!==null)&&(listProducts.marketPrice!==0)">
                                                    <div><span>{{formatNumberMoney(listProducts?.price)}}</span></div>
                                                    <div class="d-flex justify-content-between align-items-center pb-1 w-100"><!-- Start: giá cũ d-none --><span class="old-price">{{formatNumberMoney(listProducts?.marketPrice)}}</span><!-- End: giá cũ d-none -->
                                                        <div class="d-flex justify-content-center align-items-center percen-discount h-100 py-1"><span>{{round(((listProducts?.marketPrice-listProducts?.price)/listProducts?.marketPrice)*100,0)}}</span><span>%</span></div>
                                                    </div>
                                                </div><!-- End: discount price --><!-- Start: brand -->
                                                <div class="d-flex item-card-brand"><!-- Start: brand of hotdeal --><span>{{listProducts?.productBrands?.name}}</span><!-- End: brand of hotdeal --></div><!-- End: brand --><!-- Start: name -->
                                                <div class="name-div cs-pt mb-1"><span class="name text-truncate-2">{{listProducts?.name}}</span></div><!-- End: name --><!-- Start: rating and bought count -->
                                                <div class="d-flex justify-content-between align-items-center h-100 ratting-bought-count"><!-- Start: ratting -->
                                                    <div class="ratting"><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i></div><!-- End: ratting --><!-- Start: bought-count -->
                                                    <div class="bought-count"><i class="icon-basket-loaded me-1"></i><span>{{listProducts?.sellcount}}</span></div><!-- End: bought-count -->
                                                </div><!-- End: rating and bought count -->
                                            </div>
                                        </nuxt-link-locale></div><!-- End: product-card -->
                                </div>
                            </div><!-- End: list -->
                        </div><!-- End: cha -->
                    </div>
                </div>
            </section><!-- End: deals nổi bật -->
        </div><!-- End: list HOME SECTION --><!-- Start: Danh mục ngành hàng -->
        <section class="pb-2">
            <div class="container"><!-- Start: cha -->
                <div><!-- Start: title-cat -->
                    <div class="d-flex justify-content-between pt-2 pb-1"><!-- Start:  id:6KeqqzUfSonWSeLbx3YL7S-debug-component-ldr -->
                        <h4 class="6KeqqzUfSonWSeLbx3YL7S-preview-css-ldr mb-0">{{$t('danhMuc')}}</h4><!-- End:  id:6KeqqzUfSonWSeLbx3YL7S-debug-component-ldr --><i class="icon ion-chevron-right ps-4" @click="userGlobalState.showmainmenugroupproduct=true"></i>
                    </div><!-- End: title-cat --><!-- Start: list -->
                    <div class="d-flex flex-wrap box-shazing">
                        <div class="row g-2">
                            <div class="col-4" v-for="(groupProduct,index) in groupProducts" v-bind:key="groupProduct._id"><nuxt-link-locale class="text-decoration-none text-dark" :to="'/danh-muc/'+groupProduct.slug">
                                    <div class="block-item-cat mb-2 h-100 w-100 main-box-shadown"><!-- Start: img -->
                                        <div class="item-cat"><img class="img-fluid" src="/static/cosmeticscz/no-image-news.png" alt="ảnh thay thế" v-if="groupProduct.thumbnail===null"><!-- Start: gốc --><img class="img-fluid w-100" alt="ảnh của danh mục nổi bật" :src="groupProduct.thumbnail"><!-- End: gốc --></div><!-- End: img --><!-- Start: name -->
                                        <div class="d-flex justify-content-center align-items-center item-name-product two-line-truncate px-2"><span>{{groupProduct.name}}</span></div><!-- End: name -->
                                    </div>
                                </nuxt-link-locale></div>
                            <div class="col-4"><!-- Start: overlay -->
                                <div class="block-item-cat h-100 main-box-shadown" @click="userGlobalState.showmainmenugroupproduct=!userGlobalState.showmainmenugroupproduct"><!-- Start: img -->
                                    <div class="item-cat"></div><!-- End: img --><!-- Start: block-item-cat -->
                                    <div class="d-flex justify-content-center align-items-center block-item-cate-overlay">
                                        <div class="d-flex flex-column justify-content-center align-items-center item-txt-overlay"><span class="fs-27">. . .</span>
                                            <p>Xem tất cả</p>
                                        </div>
                                    </div><!-- End: block-item-cat -->
                                </div><!-- End: overlay -->
                            </div>
                        </div>
                    </div><!-- End: list -->
                </div><!-- End: cha -->
            </div>
        </section><!-- End: Danh mục ngành hàng --><!-- Start: LIST Home FAKE Highlight Group -->
        <div class="py-1"><!-- Start: deals nổi bật -->
            <section class="mb-3">
                <div class="container"><!-- Start: cha -->
                    <div class="pb-1 h-100" v-for="(listHomesectionFakeHighlightGroup,index) in listHomesectionFakeHighlightGroups" v-bind:key="listHomesectionFakeHighlightGroup._id">
                        <div class="h-100"><nuxt-link-locale class="text-decoration-none text-dark" :to="'/danh-muc/'+listHomesectionFakeHighlightGroup.groupProductSluglink">
                                <div class="d-flex justify-content-between align-items-center py-2">
                                    <h4 class="title-deal mb-0">{{listHomesectionFakeHighlightGroup.sectionName}}</h4><i class="icon ion-chevron-right ps-3 py-1"></i>
                                </div>
                            </nuxt-link-locale><!-- Start: list product scroll -->
                            <div class="d-flex flex-row list-item-cat-mo h-100">
                                <div class="col-5" v-for="(listProducts,index) in listHomesectionFakeHighlightGroup?.listProducts||[]" v-bind:key="listProducts"><!-- Start: v-if div -->
                                    <div class="d-flex flex-column item-product-scroll-mobile h-100 primary-box-shadow"><!-- Start: product-card -->
                                        <div class="product-card pointer item-transform-img h-100 p-1 p-lg-2 me-1 primary-box-shadow"><nuxt-link-locale class="text-decoration-none text-dark h-100" :to="'/san-pham/'+listProducts.slug">
                                                <div class="item-card-img mb-1 position-relative"><img class="img-fluid w-100" :src="listProducts.image" :alt="listProducts.name"></div>
                                                <div class="item-info-card pb-1"><!-- Start: Basic Price -->
                                                    <div class="d-flex justify-content-between item-card-price" v-if="listProducts.marketPrice===listProducts.price || listProducts.marketPrice===null || listProducts.marketPrice===0">
                                                        <div><span>{{formatNumberMoney(listProducts?.price)}}</span></div>
                                                    </div><!-- End: Basic Price --><!-- Start: discount price -->
                                                    <div class="d-flex flex-column justify-content-between flex-md-row item-card-price h-100" v-if="(listProducts.marketPrice!==listProducts.price)&&(listProducts.marketPrice!==null)&&(listProducts.marketPrice!==0)">
                                                        <div><span>{{formatNumberMoney(listProducts?.price)}}</span></div>
                                                        <div class="d-flex justify-content-between align-items-center pb-1 w-100"><!-- Start: giá cũ d-none --><span class="old-price">{{formatNumberMoney(listProducts?.marketPrice)}}</span><!-- End: giá cũ d-none -->
                                                            <div class="d-flex justify-content-center align-items-center percen-discount h-100 py-1"><span>{{round(((listProducts?.marketPrice-listProducts?.price)/listProducts?.marketPrice)*100,0)}}</span><span>%</span></div>
                                                        </div>
                                                    </div><!-- End: discount price --><!-- Start: brand -->
                                                    <div class="d-flex item-card-brand"><span>{{listProducts?.productBrands?.name}}</span></div><!-- End: brand --><!-- Start: name -->
                                                    <div class="name-div cs-pt mb-1"><span class="name text-truncate-2">{{listProducts?.name}}</span></div><!-- End: name --><!-- Start: rating and bought count -->
                                                    <div class="d-flex justify-content-between align-items-center h-100 ratting-bought-count"><!-- Start: ratting -->
                                                        <div class="ratting"><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i></div><!-- End: ratting --><!-- Start: bought-count -->
                                                        <div class="bought-count"><i class="icon-basket-loaded me-1"></i><span>{{listProducts?.sellcount}}</span></div><!-- End: bought-count -->
                                                    </div><!-- End: rating and bought count -->
                                                </div>
                                            </nuxt-link-locale></div><!-- End: product-card -->
                                    </div><!-- End: v-if div -->
                                </div>
                            </div><!-- End: list product scroll --><!-- Start: category -->
                            <div class="list-item-cat-mo pt-1 pb-2">
                                <div class="d-flex" v-for="(groupProductLv2,index) in groupProductLv2s" v-bind:key="groupProductLv2._id">
                                    <div v-if="groupProductLv2.groupProduct?.includes(listHomesectionFakeHighlightGroup.productsCategory)"><!-- Start: list -->
                                        <div class="d-flex justify-content-center align-items-center item-cat-bottom me-2"><nuxt-link-locale class="text-decoration-none text-dark" :to="'/danh-muc/'+groupProductLv2.slug">
                                                <p>{{groupProductLv2.name}}</p>
                                            </nuxt-link-locale></div><!-- End: list -->
                                    </div>
                                </div>
                            </div><!-- End: category -->
                        </div>
                    </div><!-- End: cha -->
                </div>
            </section><!-- End: deals nổi bật -->
        </div><!-- End: LIST Home FAKE Highlight Group --><!-- Start: Thương hiệu -->
        <section class="mb-2 py-2 bg-white">
            <div class="container">
                <div><!-- Start: title-deals -->
                    <div class="d-flex justify-content-between align-items-center py-2">
                        <h4 class="title-deal mb-0">{{$t('thuongHieu')}}</h4><nuxt-link-locale class="text-decoration-none text-dark" to="/thuong-hieu">
                            <p class="pm-0">Xem tất cả</p>
                        </nuxt-link-locale>
                    </div><!-- End: title-deals --><!-- Start: list -->
                    <div class="d-flex list-item-cat-mo">
                        <div class="col-4 me-2" v-for="(listProductBrands,index) in listProductBrandss" v-bind:key="listProductBrands._id"><nuxt-link-locale class="text-decoration-none text-dark" :to="'/thuong-hieu/'+listProductBrands.slug">
                                <div class="brand-mobile-border-div pb-2 bg-grey-default"><img class="img-fluid w-100" :src="listProductBrands.thumbnail" :alt="listProductBrands.name">
                                    <div class="d-flex justify-content-center align-items-center" style="margin-top:-20px">
                                        <div class="w-75"><img class="img-fluid w-100" :src="listProductBrands.banner" :alt="listProductBrands.name"></div>
                                    </div>
                                </div>
                            </nuxt-link-locale></div>
                    </div><!-- End: list -->
                </div>
            </div>
        </section><!-- End: Thương hiệu -->
    </div>
    <section class="pb-2 bg-bdy">
        <div class="container">
            <div class="p-2 border-rd-8 d-none"><!-- Start: title -->
                <div class="d-flex justify-content-between align-items-center">
                    <h4 class="title-cate">Sản phẩm vừa xem</h4><nuxt-link-locale class="text-decoration-none text-dark" to="/thuong-hieu">
                        <p class="pm-0">Xem tất cả</p>
                    </nuxt-link-locale>
                </div><!-- End: title --><!-- Start: list -->
                <div class="d-flex list-item-brand-mo">
                    <div class="col-3 me-2" v-for="(listProductBrands,index) in listProductBrandss" v-bind:key="listProductBrands._id">
                        <div><img class="img-fluid w-100" :src="listProductBrands.thumbnail"></div>
                    </div>
                </div><!-- End: list -->
            </div><!-- Start: gợi ý đi kèm -->
            <div class="mt-2 pt-2"><!-- Start: title-deals -->
                <div class="d-flex justify-content-center align-items-center pt-2 pb-1 position-relative title-style">
                    <div class="title-style-child bg-bdy">
                        <h4 class="title-deal mb-0 z-2">{{$t('sanPhamGoiYDanhChoBan')}}</h4>
                    </div>
                </div><!-- End: title-deals --><!-- Start: category -->
                <div class="list-item-cat-mo py-2"><!-- Start: list -->
                    <div class="d-flex justify-content-center align-items-center item-cat-bottom me-2 bg-white" v-for="(groupProductLv2,index) in groupProductLv2s" v-bind:key="groupProductLv2._id"><nuxt-link-locale class="text-decoration-none text-dark" :to="'/danh-muc/'+groupProductLv2.slug">
                            <p>{{groupProductLv2.name}}</p>
                        </nuxt-link-locale></div><!-- End: list -->
                </div><!-- End: category -->
            </div><!-- End: gợi ý đi kèm -->
            <div class="row g-0 g-1">
                <div class="col-6" v-for="(listProduct,index) in listProducts" v-bind:key="listProduct._id"><!-- Start: product-card -->
                    <div class="product-card pointer item-transform-img h-100 p-1 p-lg-2 main-box-shadown"><nuxt-link-locale class="text-decoration-none text-dark" :to="'/san-pham/'+listProduct.slug">
                            <div class="item-card-img mb-1 position-relative"><img class="img-fluid w-100" :src="listProduct.image" :alt="listProduct.name"></div>
                            <div class="item-info-card pb-1"><!-- Start: Basic Price -->
                                <div class="d-flex justify-content-between item-card-price" v-if="listProduct.marketPrice===listProduct.price || listProduct.marketPrice===null || listProduct.marketPrice===0">
                                    <div><span>{{formatNumberMoney(listProduct.price)}}</span></div>
                                </div><!-- End: Basic Price --><!-- Start: discount price -->
                                <div class="d-flex flex-column justify-content-between flex-md-row item-card-price h-100" v-if="(listProduct.marketPrice!==listProduct.price)&&(listProduct.marketPrice!==null)&&(listProduct.marketPrice!==0)">
                                    <div><span>{{formatNumberMoney(listProduct.price)}}</span></div>
                                    <div class="d-flex justify-content-between align-items-center pb-1 w-100"><!-- Start: giá cũ d-none --><span class="old-price">{{formatNumberMoney(listProduct.marketPrice)}}</span><!-- End: giá cũ d-none -->
                                        <div class="d-flex justify-content-center align-items-center percen-discount h-100 py-1"><span>{{round(((listProduct.marketPrice-listProduct.price)/listProduct.marketPrice)*100,0)}}</span><span>%</span></div>
                                    </div>
                                </div><!-- End: discount price --><!-- Start: brand -->
                                <div class="d-flex item-card-brand"><span>{{listProduct.productBrands?.name}}</span></div><!-- End: brand --><!-- Start: name -->
                                <div class="name-div cs-pt mb-1"><span class="name text-truncate-2">{{listProduct.name}}</span></div><!-- End: name --><!-- Start: rating and bought count -->
                                <div class="d-flex justify-content-between align-items-center h-100 ratting-bought-count"><!-- Start: ratting -->
                                    <div class="ratting"><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i></div><!-- End: ratting --><!-- Start: bought-count -->
                                    <div class="bought-count"><i class="icon-basket-loaded me-1"></i><span>{{listProduct.sellcount}}</span></div><!-- End: bought-count -->
                                </div><!-- End: rating and bought count -->
                            </div>
                        </nuxt-link-locale></div><!-- End: product-card -->
                </div>
            </div>
        </div>
    </section>
</div>
</template><script setup>
    import { computed,watch } from 'vue';



            const userGlobalState = useUserGlobalState();




    import {formatNumberMoney,} from '../../util';









    //replaceMome


    import round from 'lodash/round.js';




    const $device = useDevice();
    const { isMobile,isDesktop } = useDevice();
    const route = useRoute();


    definePageMeta({
        layout: "default",

    });




    let giay= ref(59);
    let phut= ref(0);
    let select= ref("");
    let currentLv1= ref("nothing here");
    let mapBrandName= ref(new Map());;









    //   variables
    if(process.env.NODE_ENV==='development'){

    }









    // computed


    //attributesCal 0




        // computed





        const clearTypeName = (api)=>{
            const funF = (obj)=>{
                if(obj){
                    console.log('obj')
                    Object.keys(obj).forEach((key)=>{
                        if(key==="__typename"){
                            delete obj.__typename;
                        } else if(Array.isArray(obj[key])){
                            obj[key].forEach((item)=>{
                                if(item)
                                    funF(item);
                            });
                        } else if(typeof (obj[key]) ==="object"&&(obj[key])){
                            if(obj[key])
                                funF(obj[key]);
                        }
                    });}
            };
            funF(api);
            return api;
        };

// mutation












        //1 setup


        let webMedias= ref({});
let listHomeMenus= ref([]);
let homeSectionss= ref([]);
let listHomesectionFakeHighlightGroups= ref([]);
let listProductBrandss= ref([]);
let groupProducts= ref([]);
let groupProductLv2s= ref([]);
let listProducts= ref([]);
let websiteConfig= ref({});

        const slug=route.params['mobile'];

let variablewebMedias={};

const resultwebMedias = await useAsyncQuery(gql` query {  webMedias {
                _id carouselBanners{ itemImage  itemLink  label  description  }
        } }`,variablewebMedias);
if(resultwebMedias.data.value&&resultwebMedias.data.value.webMedias){
webMedias.value = {...resultwebMedias.data.value.webMedias}}

let variablelistHomeMenus={sort :{orderNumber:1}, query :{displayOn:{in:["mobile"]},}};

const resultlistHomeMenus = await useAsyncQuery(gql` query
 homeMenus($query: homeMenuFindInput,$limit:Float,$skip:Float,$sort:JSONObject){
     listHomeMenus:  homeMenus(query:$query,limit:$limit,skip:$skip,sort:$sort)
     {
                label icon slug link
        }

}`,variablelistHomeMenus);
if(resultlistHomeMenus.data.value&&resultlistHomeMenus.data.value.listHomeMenus){
listHomeMenus.value = resultlistHomeMenus.data.value.listHomeMenus}

let variablehomeSectionss={sort :{orderNumber:1}, query :{type:{nin:["fake-hight-light-group","hot-sale"]},status:{eq:"active"},}};

const resulthomeSectionss = await useAsyncQuery(gql` query
 homeSectionss($query: homeSectionsFindInput,$limit:Float,$skip:Float,$sort:JSONObject){
    homeSectionss:  homeSectionss(query:$query,limit:$limit,skip:$skip,sort:$sort)
     {
                _id sectionName listProducts{ _id  name  image  price  description  slug  labelPrice  status  giaTheoKhuyenMai  productBrands{ _id name }  marketPrice  sellcount  } status slug productsCategory
        }

}`,variablehomeSectionss);
if(resulthomeSectionss.data.value&&resulthomeSectionss.data.value.homeSectionss){
homeSectionss.value = resulthomeSectionss.data.value.homeSectionss.map((item)=>{ return {...item,}});}

let variablelistHomesectionFakeHighlightGroups={sort :{orderNumber:1}, query :{type:{eq:"fake-hight-light-group"},status:{eq:"active"},}};

const resultlistHomesectionFakeHighlightGroups = await useAsyncQuery(gql` query
 homeSectionss($query: homeSectionsFindInput,$limit:Float,$skip:Float,$sort:JSONObject){
listHomesectionFakeHighlightGroups:  homeSectionss(query:$query,limit:$limit,skip:$skip,sort:$sort)
     {
                _id sectionName listProducts{ _id  name  image  price  description  groupProduct  slug  productTags  productBrands{ _id name }  status  producttype  propertyProduct{ name  key  typeValue  values  }  giaTheoKhuyenMai  giaOnline  sellcount  marketPrice  } productsCategory status slug soLuongMacDinh type groupProductSluglink kieuBoLoc
        }

}`,variablelistHomesectionFakeHighlightGroups);
if(resultlistHomesectionFakeHighlightGroups.data.value&&resultlistHomesectionFakeHighlightGroups.data.value.listHomesectionFakeHighlightGroups){
listHomesectionFakeHighlightGroups.value = resultlistHomesectionFakeHighlightGroups.data.value.listHomesectionFakeHighlightGroups.map((item)=>{ return {...item,}});}

let variablelistProductBrandss={sort :{orderNumber:1}, query :{status:{eq:true},highlight:{eq:true},}};

const resultlistProductBrandss = await useAsyncQuery(gql` query
 productBrandss($query: productBrandsFindInput,$limit:Float,$skip:Float,$sort:JSONObject){
     listProductBrandss:  productBrandss(query:$query,limit:$limit,skip:$skip,sort:$sort)
     {
                _id name slug banner thumbnail highlight status orderNumber
        }

}`,variablelistProductBrandss);
if(resultlistProductBrandss.data.value&&resultlistProductBrandss.data.value.listProductBrandss){
listProductBrandss.value = resultlistProductBrandss.data.value.listProductBrandss}

let variablegroupProducts={limit: 5,sort :{orderNumber:1}, query :{highlight:{eq:true},status:{eq:true},}};

const resultgroupProducts = await useAsyncQuery(gql` query
 groupProducts($query: groupProductFindInput,$limit:Float,$skip:Float,$sort:JSONObject){
     groupProducts:  groupProducts(query:$query,limit:$limit,skip:$skip,sort:$sort)
     {
                _id name slug thumbnail highlight status groupProduct orderNumber
        }

}`,variablegroupProducts);
if(resultgroupProducts.data.value&&resultgroupProducts.data.value.groupProducts){
groupProducts.value = resultgroupProducts.data.value.groupProducts}

let variablegroupProductLv2s={query :{status:{eq:true},}};

const resultgroupProductLv2s = await useAsyncQuery(gql` query
 groupProducts($query: groupProductFindInput,$limit:Float,$skip:Float,$sort:JSONObject){
     groupProductLv2s:  groupProducts(query:$query,limit:$limit,skip:$skip,sort:$sort)
     {
                _id name slug thumbnail highlight groupProduct status
        }

}`,variablegroupProductLv2s);
if(resultgroupProductLv2s.data.value&&resultgroupProductLv2s.data.value.groupProductLv2s){
groupProductLv2s.value = resultgroupProductLv2s.data.value.groupProductLv2s}

let variablelistProducts={limit: 6,sort :{sellcount:-1}, query :{status:{eq:true},}};

const resultlistProducts = await useAsyncQuery(gql` query
 products($query: productFindInput,$limit:Float,$skip:Float,$sort:JSONObject){
     listProducts:  products(query:$query,limit:$limit,skip:$skip,sort:$sort)
     {
                _id name image price slug productBrands{ _id  createdAt  updatedAt  name  slug  banner  thumbnail  description  status  arrayMediaPlus  widthBanner  highlight  orderNumber  } status marketPrice sellcount
        }

}`,variablelistProducts);
if(resultlistProducts.data.value&&resultlistProducts.data.value.listProducts){
listProducts.value = resultlistProducts.data.value.listProducts}

let variablewebsiteConfig={};

const resultwebsiteConfig = await useAsyncQuery(gql` query {  websiteConfig {
                seoTitle seoDescription tenThuongHieuSeo seoKeywords logo
        } }`,variablewebsiteConfig);
if(resultwebsiteConfig.data.value&&resultwebsiteConfig.data.value.websiteConfig){
websiteConfig.value = {...resultwebsiteConfig.data.value.websiteConfig}}








        //1 afterData

    mapBrandName.value=new Map(listProductBrandss.value?.map(item=>[item._id,item])||[])











        const methods=()=>{

        }







  // watch















    //meta


        const meta = [  {name: "theme-color" ,content:"#fff"}];
        let title = '';

    title =''


         title= websiteConfig.value.seoTitle;
            meta.push({ name: 'title', content:websiteConfig.value.seoTitle });

            meta.push({ name: 'description', content:websiteConfig.value.seoDescription });

            meta.push({ name: 'keywords', content:websiteConfig.value.seoKeywords });

            meta.push({ name: 'og:image', content:websiteConfig.value.logo });



   const bodyAttrs ={};
    const  htmlAttrs={};




        useHead({
            title: title,
            viewport: 'width=device-width, initial-scale=1, maximum-scale=1',
            charset: 'utf-8',
            meta: meta,
            htmlAttrs:htmlAttrs,
            bodyAttrs:bodyAttrs,
            script: [

            ],

            link:[


                { rel: 'stylesheet', href: 'https://cdnjs.cloudflare.com/ajax/libs/simple-line-icons/2.4.1/css/simple-line-icons.min.css' },

                { rel: 'stylesheet', href: 'https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css' },


                { rel: 'stylesheet', href: 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css' },


            ]

        })



</script>


<!--false-->
<script>



               import { Grid,Autoplay,Navigation,Pagination,Keyboard } from 'swiper';
              import { Swiper, SwiperSlide } from "swiper/vue";
               import "swiper/css";
                 import 'swiper/css/grid';
                   import "swiper/css/pagination";
              import "swiper/css/navigation";

    export default {
        name: "-mobile",


        components: {
            Swiper,
 SwiperSlide,
},


        data(){
            return {

                }
            },
                methods:{

                runAnimation(name,delay=0,loop=false,reverse=false){


                }



            }

        }
</script>

//update in Mon Aug 12 2024 15:31:21 GMT+0700 (Indochina Time) by loidirieng
